import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"
import withI18next from "../../i18n/withI18next"

function LayoutWrapper({ children, location, pageContext: { locale } }) {
  const { i18n } = useTranslation()

  React.useEffect(() => {
    i18n.changeLanguage(locale)
  }, [location, i18n, locale])

  return children
}
LayoutWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  pageContext: PropTypes.object,
  location: PropTypes.string,
}
export default withI18next()(LayoutWrapper)

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1200}},{"resolve":"gatsby-remark-table-of-contents","options":{"exclude":"Table of Contents","tight":false,"fromHeading":1,"toHeading":6}},{"resolve":"gatsby-remark-autolink-headers","options":{"offsetY":"80","isIconAfterHeader":true,"className":"ml-1"}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kodvizit","short_name":"Kodvizit","start_url":"/?utm_source=homescreen&utm_medium=shortcut","background_color":"#0085b7","theme_color":"#0085b7","display":"standalone","icon":"static/img/logo.png","icons":[{"src":"favicons/icon-maskable-192x192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"favicons/icon-maskable-512x512.png","sizes":"512x512","type":"image/png","purpose":"maskable"},{"src":"favicons/icon--192x192.png","sizes":"192x192","type":"image/png"},{"src":"favicons/icon--512x512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"7f10afd23b682e1cf22c966809ce47f8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-92001848-1","head":false,"anonymize":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/runner/work/Kodvizit/Kodvizit/src/components/layout-wrapper/index.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
